import React from 'react';
import * as PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage } from "react-intl";


import Layout from '../components/layout';


const propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const TopicsPage = ({data, location}) => {

  const allTopics = data.content.edges;

  return (
    <Layout data={data} location={location}>
      <article className="LandingPage Page">
        <section className="container grid-lg">
          <h1><FormattedMessage id="topics" /></h1>
          <div className="cards">
            {allTopics.map((topic, idx) => (
              <div className={`card color-1-alt`} key={idx}>
                <Link to={`/${topic.node.node_locale}/topics/${topic.node.slug}`}>
                  <h5>{topic.node.name}</h5>
                </Link>
              </div>
            ))}
          </div>
        </section>
      </article>
    </Layout>
  );
};

TopicsPage.propTypes = propTypes;

export default TopicsPage;

export const topicsPageFragment = graphql`
         fragment TopicsPageFragment on ContentfulTopicConnection {
           edges {
             node {
               node_locale
               name
               slug
             }
           }
         }
       `;
