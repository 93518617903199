import TopicsPage from '../containers/TopicsPage';

export default TopicsPage;

export const pageQuery = graphql`
         query TopicsPageEsQuery {
           site {
             siteMetadata {
               languages {
                 langs
               }
             }
           }
           content: allContentfulTopic(
             sort: { order: DESC, fields: updatedAt }
             filter: { node_locale: { eq: "es" } }
           ) {
             ...TopicsPageFragment
           }
         }
       `;
